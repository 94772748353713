'use strict';
$(window).on('load', function(){
   $('html').addClass('is-loaded');
});

$(document).ready(function() {

   var deviceAgent = navigator.userAgent.toLowerCase();
   var agentID = deviceAgent.match(/(iphone|ipod|ipad|android|webos|iemobile|opera mini|blackberry)/);

   if (agentID) {
      $('html').addClass('is-mobile');
   }

   $('img.svg').each(function() {
      var $img = $(this);
      var imgID = $img.attr('id');
      var imgClass = $img.attr('class');
      var imgURL = $img.attr('src');
      var imgWidth = $img.attr('width');

      $.get(imgURL, function(data) {
         // Get the SVG tag, ignore the rest
         var $svg = $(data).find('svg');

         // Add replaced image's ID to the new SVG
         if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
         }
         // Add replaced image's classes to the new SVG
         if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
         }

         if (typeof imgWidth !== 'undefined') {
            $svg = $svg.attr('width', imgWidth);
         }

         // Remove any invalid XML tags as per http://validator.w3.org
         $svg = $svg.removeAttr('xmlns:a');

         // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
         if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
         }

         // Replace image with new SVG
         $img.replaceWith($svg);

      }, 'xml');
   });

   $('body').on('click', '.jumper', function(e) {
      e.preventDefault();
      var anchor = $(this).attr('href');
      $('html,body').animate({ scrollTop: $(anchor).offset().top }, 1000);
   });

   $('.carousel-ad').each(function(index, el) {
      const carousel = $(el);
      const carouselPrev = carousel.parent().find('.carousel-ad__button-next');
      const carouselNext = carousel.parent().find('.carousel-ad__button-prev');
      const carouselPagination = carousel.parent().find('.carousel-ad__pagination');

      const carouselAd = new Swiper(carousel, {
         // autoplay: {
         //    delay: 8000,
         // },
         slidesPerView: 4,
         spaceBetween: 26,
         loop: false,
         speed: 1000,
         parallax: true,
         navigation: {
            nextEl: carouselPrev,
            prevEl: carouselNext,
         },
         pagination: {
            el: carouselPagination,
            clickable: true,
         },
         breakpoints: {
            1200: {
               slidesPerView: 3,
            },
            768: {
               slidesPerView: 2,
            },
            576: {
               slidesPerView: 1,
            },
            320: {
               slidesPerView: 1,
            }
         }
      });
   });


   $('.menu-toggle-mobile').on('click', function(event) {
      event.preventDefault();
      const mobileNav = $('.mobile-nav-wrap');

      if(mobileNav.hasClass('is-active')){
         mobileNav.removeClass('is-active');
      }else{
         mobileNav.addClass('is-active');
      }

   });

   $('body').on('click', '.mobile-nav-wrap li.is-dropdown > a', function(event) {
      if ($(window).width() < 1024) event.preventDefault();
      let sub = $(this).find('+ ul');
      
      if(sub.length == 0){
         sub = $(this).find('+ div');
      }

      sub.stop().slideToggle();
      $(this).toggleClass('is-active');
   });

   $('.video-player, .video-player__control').on('click', function(){
      const videoPlayer = $(this); //.parent();
      let video = videoPlayer.find('video');

      if(videoPlayer.hasClass('video-player__control')){
         video = videoPlayer.find('+ video');
         video.trigger('play');
      }
      
      // video.trigger('play');

      video.on('play', function(){
         videoPlayer.addClass('is-play');
      });

      video.on('pause ended', function(){
         videoPlayer.removeClass('is-play');
      });
   });

   // Formatando campos com mascaras
   var SPMaskBehavior = function(val) {
         return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
      },
      spOptions = {
         onKeyPress: function(val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
         }
      };

   $('.mask-phone').mask(SPMaskBehavior, spOptions);
   $('.mask-date').mask('99/99/9999');
   $('.mask-cep').mask('00000-000');
   $('.mask-cpf').mask('000.000.000-00');
   $('.mask-cnpj').mask('00.000.000/0000-00');
   $('.mask-number').mask('0#');

   // LightBox
   $(".open-fancybox").fancybox({
      // 'titleShow'     : false
   });

   SmoothScroll({
      animationTime: 600,
      stepSize: 50,
      // accelerationDelta: 100,
   });

   let wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 40,
      mobile: false,
      live: true
   });

   wow.init();

});
